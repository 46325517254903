import { Route, Routes } from "react-router-dom";
import "./App.css";
import axios from "axios";
import mixpanel from "mixpanel-browser";
import Welcome from "./components/Welcome";
import Login from "./components/Login";
import PrivateRoute from "./Routes/PrivateRoute";
import AdminRoute from "./Routes/AdminRoute";
import SingUp from "./components/Signup";
import ForgotPassword from "./components/ForgotPassword";
import Verify from "./components/pages/Verify_otp";
import Blog2 from "./components/pages/Blog";
import Application from "./components/pages/Application";
import Dashboard from "./components/pages/Dashboard";
import NotFound from "./components/pages/NotFound";
import Jobs from "./components/pages/Jobs";
import TemplatePreview from "./components/pages/TemplatePreview";
import Account from "./components/pages/Account";
import ChoseTemplate from "./components/pages/ChoseTemplate";
import JobRecomandation from "./components/pages/JobRecomandation";
import Home from "./components/pages/Home";
import Tnc from "./components/pages/Tnc";
import Privacy from "./components/pages/Privacy";
import ForgotVerify from "./components/pages/ForgotVerify";
import Chat from "./components/pages/Chat";
import SavedJob from "./components/pages/SavedJob";
import JobsForYou from "./components/pages/JobsForYou";
import Jobs2 from "./components/pages/Jobs2";
import Refundandpolidcy from "./components/pages/Refundandpolidcy";
import Contactus from "./components/pages/Contactus";
import AboutUs from "./components/pages/AboutUs";
import TestFor from "./components/pages/TestFor";
import Success from "./components/pages/Payment/Success";
import Fail from "./components/pages/Payment/Fail";
import AdminDashboard from "./components/pages/Admin/AdminDashboard";
import Blog from "./components/pages/Admin/Blog";
import SingleBlog from "./components/pages/SingleBlog";
import Sitemap from "./components/pages/Sitemap";
import ResumeBuilder from "./components/pages/Admin/ResumeBuilder"; 
import ResumeBuilderUser from "./components/ResumeBuilder/builder/ResumeBuilder.jsx"
import { useAuth } from "./components/ResumeBuilder/builder/components/context/auth";
import { useEffect } from "react";
import { useJobs } from "./components/ResumeBuilder/builder/components/context/Jobs";
import Pricing from "./components/pages/Pricing";
import Test2 from "./components/pages/Test2";
import MyfuseScore from "./components/pages/MyfuseScore.js";
import DashBoardNew from "./components/pages/DashBoardNew.js";
import ResumeScore from "./components/pages/ResumeScore.js";
import DataSharing from "./components/pages/DataSharing/DataSharing.js";
function App() {
  const { auth } = useAuth();
  let { setPayment } = useJobs();
  useEffect(() => {
    if (auth?.token !== "") {
      mixpanel.identify(auth?.user[0].id_user);
      mixpanel.people.set({
        $name: auth?.user[0].firstname,
        $email: auth?.user[0].email,
      });
      mixpanel.track("Page Loaded");
    }
  }, [auth]);

  const datalocal = JSON.parse(localStorage.getItem("auth"));
  const getData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/checkprimium`,
        {},
        {
          headers: {
            Authorization: `Bearer ${datalocal?.token}`,
          },
        }
      );
      
      setPayment({
        paymentStart: response?.data?.data?.date_joined || "",
        paymentEnd: response?.data?.data?.date_end || "",
        paymentStatus: response?.data?.data?.status || "",
        paymentAmount: response?.data?.data?.amount || "",
        produceName: response?.data?.data?.product_name || "",
      });
      
    } catch (error) {
      // Handle different types of errors appropriately
      if (error.response) {
        // Server responded with error status (404, 500, etc)
        if (error.response.status === 404) {
          // Handle no payment record found
          setPayment({
            paymentStart: "",
            paymentEnd: "",
            paymentStatus: "",
            paymentAmount: "",
            produceName: "",
          });
        }
      } else if (error.request) {
        // Request was made but no response received
        console.error("No response received:", error.request);
      } else {
        // Error in request setup
        console.error("Error setting up request:", error.message);
      }
    }
  };

  useEffect(() => {
    if (datalocal?.token) {
      getData();
    }
  }, [auth]);

  return (
    <Routes>
      <Route path="/welcome" element={<PrivateRoute />}>
        <Route path="" element={<ResumeBuilderUser />} />
        <Route path="dashboard" element={<DashBoardNew />} />
        <Route path="account" element={<Account />} />
        <Route path="jobrecomandation" element={<JobRecomandation />} />
        <Route path="jobapplication" element={<Application />} />
        <Route path="chat" element={<Chat />} />
        <Route path="saved" element={<SavedJob />} />
        <Route path="jobsForYou" element={<JobsForYou />} />
      </Route>
      <Route element={<AdminRoute />}>
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/admin/Blog" element={<Blog />} />
        <Route path="/admin/Resume" element={<ResumeBuilder />} />
      </Route>
      <Route path="/" element={<Home />} />
      <Route path="/airesume" element={<Welcome />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/myfusescore" element={<MyfuseScore />} />
      <Route path="/preview" element={<TemplatePreview />} />
      <Route path="/cancellationandrefundpolicy" element={<Refundandpolidcy />}/>
      <Route path="/resume-examples" element={<ChoseTemplate />} />
      <Route path="/aboutus" element={<AboutUs />} />
      {/* <Route path="/blog" element={<Blog2 />} /> */}
      <Route path="/contactus" element={<Contactus />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SingUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/verify/:email" element={<Verify />} />
      <Route path="/forgot-verify/:email" element={<ForgotVerify />} />
      <Route path="/jobs-in-india/*" element={<Jobs />} />
      <Route path="/sitemap" element={<Sitemap />} />
      <Route path="/term-and-conditions" element={<Tnc />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/jobs/details/:slug" element={<Jobs2 />} />
      {/* <Route path="/Blog/:slug" element={<SingleBlog />} /> */}
      <Route path="/success" element={<Success />} />
      <Route path="/fail" element={<Fail />} />
      <Route path="/test" element={<TestFor />} />
      <Route path="/test2" element={<Test2 />} />
      <Route path="/:jobsInLocation/*" element={<Jobs />} />
      <Route path="*" element={<NotFound />} />
      <Route path="dash" element={<DashBoardNew />} />
      <Route path="/resume-score" element={<ResumeScore />} />
      <Route path="/cheatsheet/:slug" element={<DataSharing />} />
    </Routes>
  );
}


export default App;
